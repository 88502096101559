// LogoRender.js
import React from 'react';
import PropTypes, { array, string } from 'prop-types';
import DefaultLogo from './DefaultLogo';
import LogoText from './LogoText';
import Avatar from '../common/Avatar';

const OrganizationLogo = ({ organizationLogo, width }) => {
  return <Avatar src={organizationLogo} rounded="soft" size="3xl" className="mr-2 align-middle" />;
};

OrganizationLogo.propTypes = {
  organizationLogo: PropTypes.oneOfType([array, string]),
  width: PropTypes.number.isRequired,
};

const LogoRender = ({
  currentuserdata,
  organizationLogo,
  isDark,
  width,
  at,
  isNavbarVerticalCollapsed,
  guru,
}) => {
  // Si se activa la prop guru, muestra el logo alternativo
  if (guru) return <DefaultLogo guru={true} isDark={isDark} width={width} />;
  
  if (at === 'auth') return <DefaultLogo isDark={isDark} width={width} />;
  if (!currentuserdata || !currentuserdata.department)
    return <DefaultLogo isDark={isDark} width={width} />;

  switch (currentuserdata.department.organization.logoTextAtr.toLowerCase().trim()) {
    case 'escore it':
    case '':
      return <DefaultLogo isDark={isDark} width={width} />;
    case 'logo':
      // Si tiene logo guardado, muestra el logo; sino, muestra DefaultLogo
      if (organizationLogo && organizationLogo[0].base64 && at !== 'navbar-vertical')
        return <OrganizationLogo organizationLogo={organizationLogo[0].base64} width={width} />;
      if (at !== 'navbar-vertical') return <DefaultLogo isDark={isDark} width={width} />;
      return !organizationLogo && at === 'navbar-vertical' ? (
        <DefaultLogo isDark={isDark} width={width} />
      ) : (
        <></>
      );
    default:
      // Si el texto incluye la palabra "logo"
      if (
        currentuserdata.department.organization.logoTextAtr
          .toLowerCase()
          .includes('logo')
      ) {
        if (at === 'navbar-top')
          return (
            <LogoText
              organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
            />
          );
        else {
          if (isNavbarVerticalCollapsed) {
            return (
              <LogoText
                organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
              />
            );
          } else {
            if (!organizationLogo)
              return (
                <LogoText
                  organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
                />
              );
            return <></>;
          }
        }
      }
      if (currentuserdata.department.organization.logoTextAtr)
        return (
          <LogoText organizationName={currentuserdata.department.organization.logoTextAtr.trim()} />
        );
  }

  return <DefaultLogo isDark={isDark} width={width} />;
};

LogoRender.propTypes = {
  currentuserdata: PropTypes.object,
  organizationLogo: PropTypes.oneOfType([array, string]),
  isDark: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  isNavbarVerticalCollapsed: PropTypes.bool,
  guru: PropTypes.bool,
};

export default LogoRender;
