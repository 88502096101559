import React, { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { isTruck } from '../../../../helpers/hcutils';

export function descriptionColumn(organizationType) {
  const istruck = isTruck(organizationType);

  // Componente de encabezado que recibe props
  function DescriptionHeader({ column, sortElement }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    // Usamos un id único para evitar conflictos si hay varias columnas
    const headerId = `descriptionHeader-${column.dataField || 'default'}`;
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
      <span>
        <span id={headerId}>
          {column.text} {sortElement}
        </span>
        <Tooltip
          delay={1500}
          placement="top"
          isOpen={tooltipOpen}
          target={headerId}
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          Sort alphabetically by Company
        </Tooltip>
      </span>
    );
  }

  const descriptionFormatter = (dataField, row) => {
    if (istruck) {
      if (row && row.contactInfo) {
        // Extraemos partes de contactInfo
        const parts = row.contactInfo.split('|');
        const middleName = parts[1];
        const company = parts[4];
        return (
          <Fragment>
            <div>
              <strong>{company}</strong>
            </div>
            <div>{middleName}</div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <div>No Contact Info</div>
          </Fragment>
        );
      }
    }
    // Si no es truck, mostramos el nombre del examen
    return row.examName || 'N/A';
  };

  const descriptionFilter = (cell, row) => {
    if (istruck) {
      if (row && row.contactInfo) {
        const parts = row.contactInfo.split('|');
        const firstName = parts[0];
        const lastName = parts[2];
        const company = parts[4];
        return `${company} ${firstName} ${lastName}`;
      } else {
        return 'No Contact Info';
      }
    }
    return row.examName || 'N/A';
  };

  return istruck
    ? [
        {
          dataField: 'descriptionSearch',
          headerClasses: 'border-0',
          text: 'Description',
          classes: 'border-0 py-2 align-middle',
          formatter: descriptionFormatter,
          sort: true,
          headerFormatter: (column, colIndex, { sortElement }) => (
            <DescriptionHeader column={column} sortElement={sortElement} />
          ),
          sortValue: (cell, row) => {
            if (istruck && row.contactInfo) {
              const parts = row.contactInfo.split('|');
              return parts[4];
            }
            return '';
          },
          filterValue: descriptionFilter
        }
      ]
    : [
        {
          dataField: 'examName',
          headerClasses: 'border-0',
          text: 'Description',
          classes: 'border-0 py-2 align-middle',
          sort: true
        }
      ];
}
