// Logo.js
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import LogoRender from './LogoRender';

const Logo = ({
  at,
  width,
  className,
  currentuserdata,
  organizationLogo,
  isNavbarVerticalCollapsed,
  guru, // Se recibe la prop guru
  ...rest
}) => {
  const { isDark } = useContext(AppContext);

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-0 mb-0': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
            'flex-center font-weight-extra-bold fs-5': at === 'report'
          },
          className
        )}
      >
        <LogoRender
          currentuserdata={currentuserdata}
          organizationLogo={organizationLogo}
          isDark={!!isDark}
          width={width}
          at={at}
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          guru={guru} // Se pasa la prop guru a LogoRender
        />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth', 'report']),
  width: PropTypes.number,
  className: PropTypes.string,
  currentuserdata: PropTypes.object,
  guru: PropTypes.bool,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
