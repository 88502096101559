import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { isTruck } from '../../../../helpers/hcutils';

export function reportIdColumn(organizationType) {
  // Componente que renderiza el encabezado con tooltip e íconos de orden
  function ReportIdHeader({ column, sortElement }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
      <span>
        {/* column.text es "Report ID" y sortElement son los íconos de orden */}
        <span id="reportIDHeader">
          {column.text} {sortElement}
        </span>
        <Tooltip
          delay={1500}
          placement="top"
          isOpen={tooltipOpen}
          target="reportIDHeader"
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          Sort by report ID number
        </Tooltip>
      </span>
    );
  }

  // Sólo retornamos la columna si la organización es de tipo Truck
  if (!isTruck(organizationType)) {
    return [];
  }

  return [
    {
      dataField: 'clinicianAssessmentId',
      text: 'Report ID',
      sort: true,       
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      headerFormatter: (column, colIndex, { sortElement }) => (
        <ReportIdHeader column={column} sortElement={sortElement} />
      )
    }
  ];
}
