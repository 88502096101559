// DefaultLogo.js
import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/hcare_imgs/scoreit_large4.svg';
import logo_light from '../../assets/img/hcare_imgs/scoreit_large_01_ligth.png';
import guruLogo from '../../assets/img/hcare_imgs/guru_logo.JPG';

const DefaultLogo = ({ isDark, width, guru }) => {
  const logoStyle = { minWidth: `${width}px` };

  if (guru) {
    return (
      <img
        id="logo"
        className="mr-2"
        src={guruLogo}
        alt="Guru Logo"
        style={{
          width: '150px',
          maxWidth: '150px',
          border: 'none',        
          borderRadius: '8px',
        }}
        
        
        
      />
    );
  }
  

  if (isDark) {
    return (
      <img
        id="logo"
        className="mr-2"
        src={logo_light}
        alt="Logo"
        width={width}
        style={logoStyle}
      />
    );
  }

  return (
    <img
      id="logo"
      className="mr-2"
      src={logo}
      alt="Logo"
      width={width}
      style={logoStyle}
    />
  );
};

DefaultLogo.propTypes = {
  isDark: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  guru: PropTypes.bool,
};

DefaultLogo.defaultProps = {
  guru: false,
};

export default DefaultLogo;
