import html2canvas from 'html2canvas';
import { hcround, splitSentences } from '../../../../../helpers/hcutils';
import 'jspdf-autotable';

export const reportHeader = (report, x, y, data, logo = null) => {
  return new Promise((resolve, reject) => {
    const addText = (fontSize, text, xOffset = 0, yOffset = 0) => {
      report
        .setFontSize(fontSize)
        .setFont(undefined, 'bold')
        .text(text, x + xOffset, y + yOffset, { maxWidth: 18 });
    };

    if (logo) {
      const image1 = new Image();
      image1.src = logo; // URL de la imagen

      image1.onload = () => {
        const width = image1.width / (72 * 2);
        const height = image1.height / (72 * 2);

        report.addImage(image1, 'JPEG', x, y - height, width, height);

        addText(11, data.organization.organizationName, width + 0.2, -height / 2);
        addText(10, data.exam.examName, 0, 0.5);

        resolve(); // Resuelve la promesa después de agregar la imagen y el texto al PDF
      };

      image1.onerror = error => {
        reject(error); // Rechaza la promesa si hay un error al cargar la imagen
      };
    } else {
      addText(11, data.organization.organizationName);
      addText(10, data.exam.examName, 0, 0.5);

      resolve(); // Resuelve la promesa si no se proporciona un logo
    }
  });
};

export const reportClinicianInformation = (report, x, y, data) => {
  const deltax = 3;
  const headerFontSize = 9;

  const addHeader = text => {
    report
      .setFontSize(headerFontSize)
      .setFont(undefined, 'bold')
      .text(text, x, y, { maxWidth: 8 });
    y += 0.8;
  };

  const addSubHeader = (label, content) => {
    report.setFont(undefined, 'bold').text(label, x, y, { maxWidth: 3 });
    report.setFont(undefined, 'normal').text(content, x + deltax, y, { maxWidth: 5 });
    y += 0.6;
  };

  addHeader('CLINICIAN INFORMATION');
  addSubHeader('Name', `${data.clinician.firstname} ${data.clinician.middlename} ${data.clinician.lastname}`);
  addSubHeader('Specialty', data.clinician.specialtyDescription);
  addSubHeader('Email', data.clinician.email);
};

export const reportSalespersonInformation = (report, x, y, data) => {
  const deltax = 3;
  const headerFontSize = 9;
  const subHeaderFontSize = 8;

  // Función para agregar encabezado
  const addHeader = text => {
    report
      .setFontSize(headerFontSize)
      .setFont(undefined, 'bold')
      .text(text, x, y);
    y += 0.8; // Espacio debajo del encabezado
  };

  // Función para agregar subencabezado con el contenido
  const addSubHeader = (label, content) => {
    if (content) {
      report
        .setFontSize(subHeaderFontSize)
        .setFont(undefined, 'bold')
        .text(label, x, y);
      report.setFont(undefined, 'normal').text(content, x + deltax, y);
      y += 0.6; // Espacio debajo del contenido
    }
  };

  // Agregar la información del cliente
  addHeader('SALESPERSON INFORMATION');

  // Verificar que los datos existan antes de pasarlos a addSubHeader
  addSubHeader(
    'Sales Representative',
    `${data.clinician.firstname || ''} ${data.clinician.middlename || ''} ${data.clinician.lastname || ''}`
  );
  addSubHeader('Email', data.clinician.email || 'N/A');
  addSubHeader('Address', data.clinician.address || 'N/A');

  // Formato de número de teléfono si es necesario
  const formattedPhoneNumber = formatPhoneNumber(data.clinician.phoneNumber || 'N/A');
  addSubHeader('Phone Number', formattedPhoneNumber || 'N/A');
};

export const reportCustomerInformation = (report, x, y, data) => {
  const deltax = 3;
  const headerFontSize = 9;
  const subHeaderFontSize = 8;

  // Función para agregar encabezado
  const addHeader = text => {
    report
      .setFontSize(headerFontSize)
      .setFont(undefined, 'bold')
      .text(text, x, y);
    y += 0.8; // Espacio debajo del encabezado
  };

  // Función para agregar subencabezado con el contenido
  const addSubHeader = (label, content) => {
    if (content) {
      report
        .setFontSize(subHeaderFontSize)
        .setFont(undefined, 'bold')
        .text(label, x, y);
      report.setFont(undefined, 'normal').text(content, x + deltax, y);
      y += 0.6; // Espacio debajo del contenido
    }
  };

  // Agregar la información del cliente
  addHeader('CUSTOMER INFORMATION');

  // Verificar que los datos existan antes de pasarlos a addSubHeader
  let fullName = `${data.firstName || ''} ${data.lastName || ''}`;
  addSubHeader('Name', `${fullName.trim() || 'N/A'}`);
  addSubHeader('Business Name', `${data.company || 'N/A'}`);
  addSubHeader('Spec Name', `${data.middleName || 'N/A'}`);
  addSubHeader('Email', data.email || 'N/A');
  addSubHeader('Address', data.address || 'N/A');

  // Formato de número de teléfono si es necesario
  const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber || 'N/A');
  addSubHeader('Phone Number', formattedPhoneNumber || 'N/A');
};

export const reportAssessmentInformation = (report, x, y, data) => {
  let deltax = 4;
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('ASSESSMENT INFORMATION', x, y, { maxWidth: 8 })
    .setFontSize(9);
  y += 0.8;
  report.setFont(undefined, 'bold').text('Date Completed (UTC)', x, y, { maxWidth: 4 });
  report.setFont(undefined, 'normal').text(data.reportdata.dateCompleted, x + deltax, y, { maxWidth: 5 });
  y += 0.6;
  report.setFont(undefined, 'bold').text('Assessment Name', x, y, { maxWidth: 4 });
  report.setFont(undefined, 'normal').text(data.exam.examName, x + deltax, y, { maxWidth: 6 });
};

export const reportChecklistChart = async (report, x, y, data) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('SCORE', x, y, { maxWidth: 3 });

  // RADIAL CHART
  const elementradialbar = document.querySelector('#ratingMeterChart');
  const canvas = await html2canvas(elementradialbar);
  const dataimg = canvas.toDataURL('image/jpeg');

  const imgProperties = report.getImageProperties(dataimg);
  const pdfWidth = report.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  const width = pdfWidth / 2;
  const height = pdfHeight / 2;

  x = 0;
  y += 0.5;
  report.addImage(dataimg, 'jpeg', x, y, width, height, undefined, 'SLOW');

  // BAR CHART
  const elementbarchart = document.querySelector('#assessmentScoreBarChart');
  const canvas1 = await html2canvas(elementbarchart);
  const dataimg1 = canvas1.toDataURL('image/jpeg');

  const imgProperties1 = report.getImageProperties(dataimg);
  const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;

  x = 1.5 + 10;
  report.addImage(dataimg1, 'jpeg', x, y, width, height, undefined, 'SLOW');

  return pdfHeight1;
};

export const reportRadialChart = async (report, x, y, data) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('Score Breakdown', x, y, { maxWidth: 3 });

  // RADIAL CHART
  const elementradialbar = document.querySelector('#radialbarchart');
  const canvas = await html2canvas(elementradialbar);
  const dataimg = canvas.toDataURL('image/png'); // Change to PNG format

  // Adjust the x and y to position the image to the left
  x = 0; // Change this value to move the image horizontally
  y += 0.5;

  // Adjust the width and height accordingly if needed
  const imageWidth = report.internal.pageSize.getWidth() / 2;
  const imageHeight = (canvas.height * imageWidth) / canvas.width;

  report.addImage(dataimg, 'PNG', x, y, imageWidth, imageHeight, undefined, 'SLOW');

  return imageHeight;
};

export const reportScoreInformation = (report, x, y, data, sectionHeight) => {
  const additionalHorizontalSpacing = 1.5;
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('SCORE BREAKDOWN', x, y, { maxWidth: 8 });

  x = additionalHorizontalSpacing + 2.5;

  let isPassingScore = false;
  y += 1;
  reportPassingScoreInformation(report, x, y, sectionHeight, data, isPassingScore);

  // Calculate the center position horizontally
  const centerX = report.internal.pageSize.getWidth() / 2 - 1;

  // Set the draw color to gray
  report.setDrawColor(169, 169, 169); // RGB values for gray color

  // Draw vertical line as separator in the center
  report.setLineWidth(0.04);
  report.line(centerX, y, centerX, y + sectionHeight);

  // Reset the draw color to default (black)
  report.setDrawColor(0);

  x = additionalHorizontalSpacing + 1;
  isPassingScore = true;
  x += report.internal.pageSize.getWidth() / 2;
  reportPassingScoreInformation(report, x, y, sectionHeight, data, isPassingScore);
};

export const reportPassingScoreInformation = (report, x, y, sectionHeight, data, isPassingScore) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text(isPassingScore ? 'Passing Score' : 'Tester Score', x, y, { maxWidth: 3 });

  const originalTextColor = report.getTextColor();
  const textColor = isPassingScore ? originalTextColor : data.score >= data.passingScore ? 'green' : 'red';
  report
    .setFontSize(24)
    .setFont(undefined, 'bold')
    .setTextColor(textColor) // Establecer color del texto
    .text(isPassingScore ? data.passingScore + '%' : data.score + '%', x + 1, y + 0.5 + sectionHeight / 2, {
      maxWidth: 8,
      align: 'center',
      verticalAlign: 'middle'
    })
    .setTextColor(originalTextColor)
    .setFontSize(9);

  if (isPassingScore) {
    report
      .setFont(undefined, 'normal')
      .text('(the recommended minimum score to pass)*', x + 1, y + 1.5 + sectionHeight / 2, {
        maxWidth: 8,
        align: 'center',
        verticalAlign: 'middle'
      });
  }
};

const addSummaryHeader = (report, x, y) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('SUMMARY', x, y, { maxWidth: 3 });
};

const addColumnHeaders = (report, x, y) => {
  const headers = ['TYPE', 'PROFICIENCY', 'FREQUENCY'];
  const headerPositions = [x, x + 14, x + 17];

  headers.forEach((header, index) => {
    report
      .setFontSize(9)
      .setFont(undefined, 'bold')
      .text(header, headerPositions[index], y, { maxWidth: 8, align: index > 0 ? 'right' : 'left' });
  });
};

const addQuestionRow = (report, x, y, question) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(question.examName, x, y, { maxWidth: 10 });
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(hcround(question.proficiency, 2), x + 14, y, { maxWidth: 1, align: 'right' });
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(hcround(question.frequency, 2), x + 17, y, { maxWidth: 1, align: 'right' });
};

const addAnswerRow = (report, x, y, answer) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(answer.question, x + 1, y, { maxWidth: 10 });
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(hcround(answer.proficiency, 2), x + 14, y, { maxWidth: 1, align: 'right' });
  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(hcround(answer.frequency, 2), x + 17, y, { maxWidth: 1, align: 'right' });

  const width = report.getTextWidth(answer.question);
  if (width >= 9.8) {
    y += 0.6;
  }
};

export const reportChecklistSummary = (report, x, y, data, showitems) => {
  addSummaryHeader(report, x, y);
  y += 1;
  addColumnHeaders(report, 1.5, y);
  y += 1;

  const pageHeight = report.internal.pageSize.height - 1.5;

  data.questionsList.forEach(question => {
    if (y >= pageHeight) {
      report.addPage();
      y = 2; // Restart height position
      addColumnHeaders(report, 1.5, y);
      y += 1;
    } else {
      y += 0.6;
    }

    addQuestionRow(report, 1.5, y, question);
    y += 0.6;

    if (showitems) {
      question.answersList.forEach(answer => {
        if (y >= pageHeight) {
          report.addPage();
          y = 2; // Restart height position
          addColumnHeaders(report, 1.5, y);
          y += 1;
        } else {
          y += 0.6;
        }

        addAnswerRow(report, 1.5, y, answer);

        const maxWidth = 9.8; // Ancho máximo permitido para el texto
        const width = report.getTextWidth(answer.question);
        const numLines = Math.ceil(width / maxWidth) - 1;
        y += numLines * 0.6;
      });
    }
  });

  return y;
};

export const reportChecklistScale = (report, x, y, data) => {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('SCALE', x, y, { maxWidth: 3 });

  x = 1.5;
  y += 1;
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('PROFICIENCY EXPERIENCE', x, y, { maxWidth: 8 });
  x = 1.5 + 14;
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text('FREQUENCY EXPERIENCE', x, y, { maxWidth: 8, align: 'right' });

  let pageHeight = report.internal.pageSize.height - 1.5;
  data.forEach(scale => {
    if (y >= pageHeight) {
      report.addPage();
      y = 2; // Restart height position

      x = 1.5;
      report
        .setFontSize(9)
        .setFont(undefined, 'bold')
        .text('PROFICIENCY EXPERIENCE', x, y, { maxWidth: 8 });
      x = 1.5 + 14;
      report
        .setFontSize(9)
        .setFont(undefined, 'bold')
        .text('FREQUENCY EXPERIENCE', x, y, { maxWidth: 8, align: 'right' });
      y += 1;
    } else {
      y += 0.6;
    }
    x = 1.5;
    report
      .setFontSize(9)
      .setFont(undefined, 'normal')
      .text(scale.proficiencyexperience, x, y, { maxWidth: 10 });
    x = 1.5 + 10;
    report
      .setFontSize(9)
      .setFont(undefined, 'normal')
      .text(scale.frequencyexperience, x, y, { maxWidth: 10, align: 'left' });
  });
};

const calculateTextLines = (report, text, maxWidth) => {
  const textWidth = report.getTextWidth(text);

  const maxLineWidth = maxWidth * 1.2;

  if (textWidth <= maxLineWidth) {
    return 1;
  }

  const estimatedLines = textWidth / maxLineWidth;
  const estimatedTextLines = Math.ceil(estimatedLines);

  return Math.max(estimatedTextLines, 1);
};

export const reportFormSummary = async (report, x, y, data) => {
  // const title = 'REGISTRATION/OPERATION';
  const title = '';
  await reportFormSummaryExt(report, x, y, data, title);
};

export const reportExamSummary = async (report, x, y, data) => {
  const title = 'Incorrectly Answered Questions';
  await reportSummary(report, x, y, data, title);
};

const reportSummary = async (report, x, y, data, title) => {
  const pageWidth = report.internal.pageSize.getWidth();
  const columnWidth = (pageWidth - 10) / 2; // Dividir la página en dos columnas con un espacio
  const imageCache = {};

  const groupedData = Object.values(
    data.incorrectAnswersList.reduce((acc, question) => {
      if (!acc[question.question_number]) {
        acc[question.question_number] = {
          question_text: question.question_text,
          questions: []
        };
      }
      acc[question.question_number].questions.push(question);
      return acc;
    }, {})
  );

  x = 1.5;
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text(title, x, y, { maxWidth: 15 });
  y += 0.75;

  const addImageToPDF = async (imageLocation, x, y, imageHeight) => {
    if (!imageCache[imageLocation]) {
      const image = new Image();
      image.src = require(`../../../../../assets/img/questions/${imageLocation}.jpg`);
      await image.onload;
      imageCache[imageLocation] = image;
    }
    const image = imageCache[imageLocation];
    const originalImageWidth = image.naturalWidth;
    const originalImageHeight = image.naturalHeight;
    const aspectRatio = originalImageWidth / originalImageHeight;
    const imageWidth = imageHeight * aspectRatio;

    report.addImage(image, 'JPEG', x, y, imageWidth, imageHeight);
  };

  for (const group of groupedData) {
    for (const [qIndex, question] of group.questions.entries()) {
      x = 1.5;

      const questionHeight =
        qIndex === 0 ? calculateTextLines(report, group.question_text, columnWidth) * 0.3 + 0.6 : 0;
      const responseHeight = calculateTextLines(report, question.wrong_answer, columnWidth) * 0.3 + 0.6;
      const maxRowHeight = Math.max(questionHeight, responseHeight);

      if (y + maxRowHeight > report.internal.pageSize.getHeight() - 1) {
        report.addPage();
        y = 1.5;
      }

      if (qIndex === 0) {
        report
          .setFontSize(9)
          .setFont(undefined, 'normal')
          .text(group.question_text, x, y, { maxWidth: columnWidth });

        if (question.image_location) {
          const imageHeight = 2;

          if (y + imageHeight + 1 > report.internal.pageSize.getHeight() - 1) {
            report.addPage();
            y = 1.5;
          }

          await addImageToPDF(question.image_location, x, y, imageHeight);
        }
      }

      x += columnWidth + 4; // Ajuste para aumentar la separación entre columnas
      report
        .setFontSize(9)
        .setFont(undefined, 'normal')
        .text(question.wrong_answer, x, y, { maxWidth: columnWidth });

      y += maxRowHeight;
    }
  }
};

// Función para obtener la longitud de un texto en negritas
function getBoldTextWidth(doc, texto) {
  const originalFont = doc.getFont();
  const originalFontSize = doc.getFontSize();

  doc.setFont(undefined, 'bold');

  const anchoTexto = doc.getTextWidth(texto);

  doc.setFont(originalFont.fontName, originalFont.fontStyle);
  doc.setFontSize(originalFontSize);

  return anchoTexto;
}

// Función para agregar texto con sombra
function addShadedText(doc, texto, x, y, colorFondo = [233, 236, 249], padding = 0.3) {
  let anchoTexto = getBoldTextWidth(doc, texto);

  let altoRect = 0.6;

  doc.setFillColor(...colorFondo);

  doc.rect(x, y - 0.4, anchoTexto + 2 * padding, altoRect, 'F');

  doc
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text(texto, x + padding, y); // y + 10 para ajustar la altura del texto
}

function hayDiferenciasEnQuestionExtendedDescription(arrayDatos) {
  // Creamos un set con todos los 'questionExtendedDescription'
  const valores = new Set(arrayDatos.map(item => item.questionExtendedDescription));

  // Si el set tiene más de un elemento, significa que hay diferencias
  return valores.size > 1;
}

const reportFormSummaryExt = async (report, x, y, data, title) => {
  const pageWidth = report.internal.pageSize.getWidth();
  const columnWidth = (pageWidth - 10) / 2; // Dividir la página en dos columnas con un espacio
  let page = 1;

  if (page === 1) {
    addPageNumber(report, 1, x + pageWidth - 3.2, 28.1);
  }

  const groupedData = Object.values(
    data.incorrectAnswersList.reduce((acc, question) => {
      if (!acc[question.question_number]) {
        acc[question.question_number] = {
          question_text: question.question_text,
          questions: []
        };
      }
      acc[question.question_number].questions.push(question);
      return acc;
    }, {})
  );

  x = 1.5;
  y += 0.75;

  var old_calGroupText = '';
  var tquestionExtendedDescription = '';

  function CalGroupText(calGroupText, x, y) {
    report
      .setFontSize(9)
      .setFont(undefined, 'bold')
      .text(calGroupText, x, y, { maxWidth: 15 });
  }

  groupedData.map((group, gIndex) => {
    group.questions.map((question, qIndex, questionall) => {
      if (question.validOtherDetail || question.calGroupText) {
        if (question.questionExtendedDescription && question.wrong_answer) {
          x = 1.5;

          const questionHeight =
            qIndex === 0 ? calculateTextLines(report, group.question_text, columnWidth) * 0.3 + 0.6 : 0;
          const responseHeight = calculateTextLines(report, question.wrong_answer, columnWidth) * 0.3 + 0.6;
          const maxRowHeight = Math.max(questionHeight, responseHeight);

          if (y + maxRowHeight > report.internal.pageSize.getHeight() - 2) {
            report.addPage();
            page++;
            addPageNumber(report, page, x + pageWidth - 3.2, 28.1);
            y = 1.5;
          }

          if (question.calGroupText && old_calGroupText !== question.calGroupText) {
            old_calGroupText = question.calGroupText;
            CalGroupText(question.calGroupText, x, y);
            y += 0.75;
          }

          if (question.question_text) {
            if (
              question.questionExtendedDescription &&
              tquestionExtendedDescription !== question.questionExtendedDescription
            ) {
              tquestionExtendedDescription = question.questionExtendedDescription;
              ({ y, page } = addParagraph(question.questionExtendedDescription, x, y, page, pageWidth, report));
            }

            addShadedText(
              report,
              `${group.question_text.trim()}: ${question.wrong_answer ? question.wrong_answer.trim() : ''}`,
              x,
              y,
              [233, 236, 249],
              0.3
            );

            y += 0.9;

            if (questionall.length === 1 || hayDiferenciasEnQuestionExtendedDescription(questionall)) {
              ({ y, page } = addCommentText(y, report, page, x, pageWidth, question));

              y += 1.9;
            }
          } else {
            addShadedText(
              report,
              `${question.questionExtendedDescription.trim()}: ${
                question.wrong_answer ? question.wrong_answer.trim() : ''
              }`,
              x,
              y,
              [233, 236, 249],
              0.3
            );

            y += 0.9;
          }

          if (!question.questionExtendedDescription) {
          }

          splitSentences(question.validOtherDetail).map((t, index) => {
            ({ y, page } = addParagraph(t, x, y, page, pageWidth, report));
            return index;
          });

          if (
            questionall.length > 1 &&
            qIndex === questionall.length - 1 &&
            question.calGroupText.length > 0 &&
            question.wrong_answer &&
            question.questionExtendedDescription
          ) {
            ({ y, page } = addCommentText(y, report, page, x, pageWidth, question));
            y += 1.9;
          }
        } else {
          splitSentences(question.validOtherDetail).map((t, index) => {
            ({ y, page } = addParagraph(t, x, y, page, pageWidth, report));
            return index;
          });
        }
      }
      if (
        question.validOtherDetail === '' &&
        question.calGroupText === '' &&
        question.wrong_answer &&
        question.questionExtendedDescription
      ) {
        addShadedText(
          report,
          `${question.questionExtendedDescription.trim()}: ${question.wrong_answer.trim()}`,
          x,
          y,
          [233, 236, 249],
          0.3
        );
        y += 0.9;
      }
      return qIndex;
    });
    return gIndex;
  });
};

function addCommentText(y, report, page, x, pageWidth, question) {
  if (y + 1.8 > report.internal.pageSize.getHeight() - 2) {
    report.addPage();
    page++;
    addPageNumber(report, page, x + pageWidth - 3.2, 28.1);
    y = 1.5;
  }

  report.setLineWidth(0.01);

  report.rect(x, y - 0.4, 18, 1.7);

  report
    .setFontSize(9)
    .setFont(undefined, 'normal')
    .text(question.commentText || '', x + 0.2, y, { align: 'justify', maxWidth: 17.6 });
  return { y, page };
}

function addParagraph(text, x, y, page, pageWidth, report) {
  let textoProcesado = ajustarTexto(report, text, y, report.internal.pageSize.getHeight() - 2);

  report.setFont(undefined, 'normal').text(x, y, textoProcesado.textoAjustado, { align: 'justify', maxWidth: 18 }); // Añadir el texto en la posición (x, y)

  y +=
    report.getLineHeightFactor() *
    report.getTextDimensions(textoProcesado.textoAjustado).h *
    report.splitTextToSize(textoProcesado.textoAjustado, 18).length;
  y += 0.4;

  if (textoProcesado.textoRestante.length > 0) {
    report.addPage();
    page++;
    addPageNumber(report, page, x + pageWidth - 3.2, 28.1);
    y = 1.5;
    report.setFont(undefined, 'normal').text(x, y, textoProcesado.textoRestante, { align: 'justify', maxWidth: 18 });

    y +=
      report.getLineHeightFactor() *
      report.getTextDimensions(textoProcesado.textoRestante).h *
      report.splitTextToSize(textoProcesado.textoRestante, 18).length;
    y += 0.4;
  }

  return { y, page };
}

function addPageNumber(report, page, x, y) {
  report
    .setFontSize(9)
    .setFont(undefined, 'bold')
    .text(page.toString(), x, y);
}

const ajustarTexto = (reporte, texto, posicionInicial, posicionFinal) => {
  // Dividir el texto en líneas
  let lineas = reporte.splitTextToSize(texto, 18); // 180 es el ancho máximo de cada línea

  let textoAjustado = '';
  let textoRestante = '';

  for (let i = 0; i < lineas.length; i++) {
    const linea = lineas[i];
    // Calcular la nueva posición si se añade esta línea
    const nuevaPosicion =
      posicionInicial + reporte.getLineHeightFactor() * reporte.getTextDimensions(texto).h * (i + 1);

    if (nuevaPosicion <= posicionFinal) {
      // Si la línea cabe, añadirla al texto ajustado
      textoAjustado += ' ' + linea;
    } else {
      // Si la línea no cabe, añadir el resto del texto al texto restante
      textoRestante = lineas.slice(i).join(' ');
      break;
    }
  }

  return {
    textoAjustado: textoAjustado.trim(),
    textoRestante: textoRestante.trim()
  };
};

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber === null) {
    return '';
  }
  const numStr = phoneNumber.toString();

  // Remover cualquier carácter que no sea número
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  if (cleaned.length === 11) {
    return cleaned.replace(/^(\d)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3-$4');
  } else if (cleaned.length === 10) {
    return cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
  } else {
    return cleaned;
  }
}
