import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, MemoryRouter, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import AppContext from './../context/Context';
import loadable from '@loadable/component';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

// Incluimos las rutas de /guru, incluyendo /guru/login y /guru/register
const initialEntries = [
  '/authentication/password-reset',
  '/authentication/register',
  '/authentication/loginexam',
  '/guru/login',
  '/guru/register',
  '/guru/forgot-password',
  '/'
];

const getInitialIndex = pathname => {
  if (pathname === '/authentication/password-reset') return 0;
  if (pathname === '/authentication/register') return 1;
  if (pathname === '/authentication/loginexam') return 2;
  if (pathname === '/guru/login') return 3;
  if (pathname === '/guru/register') return 4;
  if (pathname === '/guru/forgot-password') return 5;
  return 6;
};

const Layout = () => {
  const { currentuser, setCurrentUser } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  useEffect(() => {
    if (currentuser && Date.now() - currentuser.startat > currentuser.jwtExpirationMs) {
      setCurrentUser(null);
    }
  }, [currentuser, setCurrentUser]);

  return (
    <MemoryRouter initialEntries={initialEntries} initialIndex={getInitialIndex(location.pathname)}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/authentication" component={AuthBasicLayout} />
        {/* Redirige "/guru" a "/guru/login" */}
        <Route exact path="/guru">
          <Redirect to="/guru/login" />
        </Route>
        {/* Todas las rutas que comiencen por /guru se renderizan con guru=true */}
        <Route
          path="/guru"
          render={props =>
            currentuser === null ? (
              <AuthBasicLayout guru={true} {...props} />
            ) : (
              <DashboardLayout {...props} />
            )
          }
        />
        <Route
          path="/"
          render={props =>
            currentuser === null ? (
              <Redirect to="/guru/login" />//<Redirect to="/authentication/login" />
            ) : (
              <DashboardLayout {...props} />
            )
          }
        />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </MemoryRouter>
  );
};

export default Layout;
