import React, { useContext } from 'react';
import { Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';

import AppContext from '../../context/Context';

const TopNavRightSideNavItem = () => {
  const { currentuser, setCurrentUser } = useContext(AppContext);

  return (
    (currentuser !== undefined || currentuser !== null) && (
      <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
        {currentuser && <ProfileDropdown currentuser={currentuser} setCurrentUser={setCurrentUser} />}
      </Nav>
    )
  );
};

export default TopNavRightSideNavItem;
