import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback, Input } from 'reactstrap';
import { removeChars, splitSentences } from '../../../helpers/hcutils';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropQuestion = ({
  question,
  proposedanswers,
  onChange,
  invalid,
  answer,
  selectedExtendedDescription,
  selectedOtherDetail,
  invalidMessage
}) => {
  const handleChange = e => {
    const { value } = e.target;

    const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(value, 10));

    if (selectedOption) {
      // Pasar la nueva información adicional al componente padre
      onChange({
        selectedValue: value,
        selectedExtendedDescription: selectedOption.questionExtendedDescription || '',
        selectedOtherDetail: splitSentences(selectedOption.validOtherDetail) || ''
      });
    } else {
      // Si no se selecciona una opción válida
      onChange({
        selectedValue: value,
        selectedExtendedDescription: '',
        selectedOtherDetail: ''
      });
    }
  };

  const formattedQuestion = removeChars(question);

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div style={{ overflowY: 'visible', lineHeight: '1.5em', paddingRight: '10px' }}>
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label for="selectOption" style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem', display: 'block' }}>
        {formattedQuestion}
      </Label>
      <Input type="select" name="answer" id="selectOption" value={answer} onChange={handleChange} invalid={invalid}>
        <option value="">Select an option</option>
        {proposedanswers.map(proposedanswer => (
          <option key={proposedanswer.validAnswerId} value={proposedanswer.validAnswerId}>
            {proposedanswer.validAnswerText}
          </option>
        ))}
      </Input>
      {invalid && (
        <FormFeedback style={{ display: 'contents' }}>{invalidMessage || 'Please select an answer!'}</FormFeedback>
      )}
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div
            style={{
              overflowY: 'visible',
              lineHeight: '1.5em',
              paddingRight: '10px',
              marginTop: '1rem'
            }}
          >
            {Array.isArray(selectedOtherDetail) ? (
              selectedOtherDetail.map((text, index) => (
                <>
                  <p className="mb-0" key={index}>
                    {text}
                  </p>
                  <br />
                </>
              ))
            ) : (
              <p>{selectedOtherDetail}</p>
            )}
          </div>
        </>
      )}
    </FormGroup>
  );
};

DropQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  invalid: false,
  answer: '',
  invalidMessage: 'Please select an answer!'
};

DropQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.arrayOf(
    PropTypes.shape({
      validAnswerId: PropTypes.number.isRequired,
      validAnswerText: PropTypes.string.isRequired,
      validOtherDetail: PropTypes.string,
      questionExtendedDescription: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  answer: PropTypes.any,
  selectedExtendedDescription: PropTypes.string, // Nueva prop
  selectedOtherDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // Nueva prop
  invalidMessage: PropTypes.string
};

export default DropQuestion;
