import React, { useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';

export function statusColumn() {
  // Componente para el header con tooltip e íconos de orden
  function StatusHeader({ column, sortElement }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    // Generamos un id único para asociar el tooltip
    const headerId = `statusHeader-${column.dataField || 'default'}`;

    return (
      <span>
        <span id={headerId}>
          {column.text} {sortElement}
        </span>
        <Tooltip
          delay={1500}
          placement="top"
          isOpen={tooltipOpen}
          target={headerId}
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          Click to move New Reports to top
        </Tooltip>
      </span>
    );
  }

  const stateFormatter = (dataField, row) => {
    if (row) {
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Badge color="soft-primary" className="fs--1" pill>
              New
            </Badge>
          );
        case 'initiated':
          return (
            <Badge color="soft-info" className="fs--1" pill>
              Initiated
            </Badge>
          );
        case 'in progress':
          return (
            <Badge color="soft-warning" className="fs--1" pill>
              In progress
            </Badge>
          );
        case 'complete':
          return (
            <Badge color="soft-success" className="fs--1" pill>
              Completed
            </Badge>
          );
        default:
          return (
            <Badge color="soft-danger" className="fs--1" pill>
              Undefined
            </Badge>
          );
      }
    }
    return null;
  };

  return [
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: stateFormatter,
      align: 'center',
      headerAlign: 'center',
      sort: true,
      headerFormatter: (column, colIndex, { sortElement }) => (
        <StatusHeader column={column} sortElement={sortElement} />
      )
    }
  ];
}
