import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback, CardImg } from 'reactstrap';
import RadioAnswer from './radioAnswer';
import { beautyPipeline, beautyPipelineCeiling, removeChars, splitSentences } from '../../../helpers/hcutils';
import DeselectableRadioAnswer from './deselectableRadioAnswer';
// import classNames from 'classnames';
// import CustomTab from './customTap';

const RadioQuestion = ({
  question,
  image,
  proposedanswers,
  onChange,
  invalid,
  useranswer,
  selectedExtendedDescription,
  selectedOtherDetail,
  inline,
  isDeselectable,
  invalidMessage
}) => {
  const [radioSelectedValue, setRadioSelectedValue] = useState(null);
  // Se inicializa y mantiene el control del Tab activo en el componente CustomTap
  // const [activeTab, setActiveTab] = useState('0');

  // inicializa el valor que esta seleccionado, null si no existe seleccion
  useEffect(() => {
    setRadioSelectedValue(useranswer ?? null);
  }, [question, useranswer]);

  const handleRadioClick = e => {
    const value = parseInt(e.target.value);

    if (isDeselectable)
      if (radioSelectedValue === value) {
        setRadioSelectedValue(null); // Deselecciona si ya estaba seleccionado

        onChange({
          selectedValue: null,
          selectedExtendedDescription: '',
          selectedOtherDetail: ''
        });
      } else {
        setRadioSelectedValue(value); // Selecciona nuevo valor
      }
    else setRadioSelectedValue(value);
  };

  // Función para manejar el cambio en la selección del radio
  const handleRadioChange = e => {
    const selectedValue = e.target.value;
    const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(selectedValue, 10));

    if (selectedOption) {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: selectedOption.questionExtendedDescription || '',
        selectedOtherDetail: splitSentences(selectedOption.validOtherDetail || '') || ''
      });
    } else {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: '',
        selectedOtherDetail: ''
      });
    }
  };

  let formattedQuestion = removeChars(question);
  if (!formattedQuestion.includes('?|')) formattedQuestion = formattedQuestion.replace('?', '?\n\n');
  if (formattedQuestion.includes('|') && formattedQuestion.includes('^')) {
    formattedQuestion = beautyPipelineCeiling(formattedQuestion);
  }
  if (formattedQuestion.includes('|')) {
    formattedQuestion = beautyPipeline(formattedQuestion);
  }

  const RenderProposedAnswers = props => {
    const { proposedanswers } = props;

    if (isDeselectable) {
      return proposedanswers.map((proposedanswer, index) => (
        <DeselectableRadioAnswer
          key={proposedanswer.validAnswerId}
          answerid={proposedanswer.validAnswerId || ''} // Asegúrate de que answerid no sea NaN o undefined
          answertext={proposedanswer.validAnswerText}
          inline={inline ? true : false}
          onClick={handleRadioClick}
          onChange={handleRadioChange}
          value={proposedanswer.validAnswerId || ''} // Verifica que value no sea NaN o undefined
          invalid={invalid}
          checked={radioSelectedValue === proposedanswer.validAnswerId}
          disabled={proposedanswer.isIncompatible === "1"}
          tooltiptext={proposedanswer.isIncompatible === "1" ?  proposedanswer.toolTipData : ''}
        />
      ));
    } else {
      return proposedanswers.map((proposedanswer, index) => (
        <RadioAnswer
          key={proposedanswer.validAnswerId}
          answerid={proposedanswer.validAnswerId || ''} // Asegúrate de que answerid no sea NaN o undefined
          answertext={proposedanswer.validAnswerText}
          inline={inline ? true : false}
          onClick={handleRadioClick}
          onChange={handleRadioChange}
          value={proposedanswer.validAnswerId || ''} // Verifica que value no sea NaN o undefined
          invalid={invalid}
          checked={radioSelectedValue === proposedanswer.validAnswerId}
          //tooltiptext={proposedanswer.validOtherDetail || ''}
        />
      ));
    }
  };

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div
            className="common-font"
            style={{ moverflowY: 'visible', lineHeight: '1.5em', paddingRight: '10px' }}
          >
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label for="selectOption" style={{ whiteSpace: 'pre-wrap' }} className="common-font">
        {formattedQuestion}
      </Label>
      {image && (
        <figure className="figure d-block mx-auto" style={{ maxWidth: 'max-content' }}>
          <CardImg src={require(`../../../assets/img/questions/${image}.jpg`)} alt="Question image" />
        </figure>
      )}
      <div>
        {/* {proposedanswers.map((proposedanswer, index) => ( */}
        <RenderProposedAnswers proposedanswers={proposedanswers} />
        {/* ))} */}
      </div>
      {invalid && (
        <FormFeedback style={{ display: 'contents' }}>{invalidMessage || 'Please select an answer!'}</FormFeedback>
      )}
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div
            className="common-font"
            style={{
              overflowY: 'visible',
              lineHeight: '1.5em',
              paddingRight: '10px',
              marginTop: '1rem'
            }}
          >
            {Array.isArray(selectedOtherDetail) ? (
              selectedOtherDetail.map((text, index) => (
                <>
                  <p className="mb-0" key={index}>
                    {text}
                  </p>
                  <br />
                </>
              ))
            ) : (
              <p className="mb-0">{selectedOtherDetail}</p>
            )}
          </div>
        </>
      )}
    </FormGroup>
  );
};

export default RadioQuestion;

RadioQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  inline: false,
  invalid: false,
  isDeselectable: false,
  selectedOtherDetail: '',
  invalidMessage: 'Please select an answer!'
};

RadioQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.array,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  selectedExtendedDescription: PropTypes.string,
  selectedOtherDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isDeselectable: PropTypes.bool,
  invalidMessage: PropTypes.string
};
