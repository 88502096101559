import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  Spinner,
  FormGroup,
  NavItem,
  NavLink,
  Tooltip
} from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';

import RadioQuestion from './radioQuestion';
import CheckQuestion from './checkQuestion';
import CheckListQuestion from './checkListQuestion';
import DropQuestion from './dropQuestion';
import DropMultipleQuestion from './dropMultipleQuestion';

import ExamService from '../../../services/exam/exam.service';
import './questions.css';
import { splitSentences } from '../../../helpers/hcutils';
import CustomTab from './customTap';
import PageFilterableTable from '../PageFilterableTable';
import classNames from 'classnames';
import CustomToolTip from '../common/CustomToolTip';

const RADIOQUESTION_TYPE = 'RADIOQUESTION';
const CHECKLISTQUESTION_TYPE = 'CHECKLISTQUESTION';
const CHECKQUESTION_TYPE = 'CHECKQUESTION';
const DROPQUESTION_TYPE = 'DROPQUESTION';
const DROP_MULTIPLE_QUESTION_TYPE = 'DROP_MULTIPLE_QUESTION';
const UNKNOWN_QUESTION_TYPE = 'UNKNOWN_QUESTION';

const ANCHO_PC_VIEW = 500; // Ancho mínimo para vista de PC
const IS_MOBILE_VIEW = window.innerWidth < ANCHO_PC_VIEW;
const OTHER_STR = 'other';
const ITEMSBYPAGE = 8;

const PageNofM = props => {
  return (
    <p className={props.className}>
      {props.n} of {props.m}
    </p>
  );
};

const CheckListDescription = props => {
  return (
    <Alert className="p-1 mb-1" color="primary">
      <p>Please rate your level of experience:</p>
      <p>
        <strong>
          Frequency and Proficiency scale will assess your experience (within the last year) using the following (check
          the appropriate boxes below when going through the assessment):
        </strong>
      </p>
      <p>Scale:</p>
      <ul>
        <li>
          <strong>N/A- No Experience / No Patient Care</strong>
        </li>
        <li>
          <strong>Level 1 - Limited Experience / Rarely Done</strong>
        </li>
        <li>
          <strong>Level 2 - May Need Some Review (1-2 month)</strong>
        </li>
        <li>
          <strong>Level 3 - Occasionally (2-3 weekly)</strong>
        </li>
        <li>
          <strong>Level 4 - Experience / Frequently Done (daily and weekly)</strong>
        </li>
      </ul>
    </Alert>
  );
};

const columns = [
  {
    dataField: 'questionText',
    headerClasses: 'border-0',
    text: 'Question',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    headerStyle: { width: '33%' },
    style: { width: '33%' }
  },
  {
    dataField: 'questionAnswer',
    headerClasses: 'border-0',
    text: 'Answer',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    headerStyle: { width: '33%' },
    style: { width: '33%' }
  },
  {
    dataField: 'questionStandard',
    headerClasses: 'border-0',
    text: 'Standard',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    headerStyle: { width: '33%' },
    style: { width: '33%' }
  },
  {
    dataField: 'questionId',
    hidden: true
  }
];

const ExamDialog = props => {
  const { visible, setVisible, user, exam, backdrop, setExamStateChanged, isTruck } = props;

  const initialForm = { questionId: 0, answer: null };

  // Confirm dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Save and Exit dialog
  const [showSaveExitDialog, setShowSaveExitDialog] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [limits, setLimits] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex] = useState(1);
  const [lastquestionid, setLastquestionid] = useState(0);

  // Respuesta de la pregunta actual
  const [form, setForm] = useState(initialForm);
  const [invalidQuestion, setInvalidQuestion] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');

  const [selectedExtendedDescription, setSelectedExtendedDescription] = useState('');
  const [selectedOtherDetail, setSelectedOtherDetail] = useState('');
  const [inComments, setInComments] = useState('');

  const [isDeselectable, setIsDeselectable] = useState(false);

  const [activeTab, setActiveTab] = useState('0');
  const [currentAnsweredQuestions, setCurrentAnsweredQuestions] = useState([]);

  const [showResetAnswersDialog, setShowResetAnswersDialog] = useState(false);

  const [nextBotonIsVisible, setNextBotonIsVisible] = useState(true);

  const [selectedNavigationRow, setSelectedNavigationRow] = useState(null);
  const [currentNavigationPage, setCurrentNavigationPage] = useState(1);
  const [examStatus, setExamStatus] = useState({ startstatus: exam.status.toLowerCase(), endstatus: '' });

  const NEXT_ACTION_WAIT = 2500;

  // Función para manejar el doble click en la navegación del examen de tipo guided
  const handleDoubleClickOnNavigationGuided = selectedQuestionId => {
    ExamService.nextQuestion(exam.examId, user.id, parseInt(selectedQuestionId), exam.clinicianAssessmentId)
      .then(response => {
        setInvalidQuestion(false);
        fillCurrentAnswer(response);
        setCurrentQuestion(response);
        if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
        setActiveTab('0');
        setIsLoaded(true);
      })
      .catch(error => {
        toastErrorMessage(error);
      });
  };

  const handleNotesChange = e => {
    setInComments(e.target.value);
  };

  const fillCurrentAnswer = question => {
    if (isRadioQuestion(question.questionTypeDescription)) {
      const answerId = question.clinicianAnswersList[0]?.clinicianAnswerId;
      setForm({
        questionId: question.questionId,
        answer: answerId ? `${answerId}` : ''
      });
      const selectedOption = question.validAnswers.find(option => option.validAnswerId === parseInt(answerId, 10));
      if (selectedOption) {
        setSelectedExtendedDescription(selectedOption.questionExtendedDescription || '');
        setSelectedOtherDetail(splitSentences(selectedOption.validOtherDetail || '') || '');
      } else {
        const firstOption = question.validAnswers[0];
        setSelectedExtendedDescription(firstOption.questionExtendedDescription || '');
        setSelectedOtherDetail('');
      }
    } else if (isDropQuestion(question.questionTypeDescription)) {
      const answerId = question.clinicianAnswersList[0]?.clinicianAnswerId;
      if (answerId) {
        const selectedOption = question.validAnswers.find(option => option.validAnswerId === parseInt(answerId, 10));
        if (selectedOption) {
          setSelectedExtendedDescription(selectedOption.questionExtendedDescription || '');
          setSelectedOtherDetail(splitSentences(selectedOption.validOtherDetail || '') || '');
        }
        setForm({
          questionId: question.questionId,
          answer: answerId.toString()
        });
      }
      if (!answerId && question.validAnswers.length === 1) {
        setSelectedExtendedDescription(question.validAnswers[0].questionExtendedDescription || '');
        setSelectedOtherDetail(splitSentences(question.validAnswers[0].validOtherDetail) || '');
        setForm({
          questionId: question.questionId,
          answer: question.validAnswers[0].validAnswerId.toString()
        });
      }
      if (!answerId && question.validAnswers.length > 1) {
        setSelectedExtendedDescription(question.validAnswers[0].questionExtendedDescription || '');
        setSelectedOtherDetail('');
        setForm({
          questionId: question.questionId,
          answer: ''
        });
      }
    } else if (isCheckListQuestion(question.questionTypeDescription)) {
      let answersobj = {};
      question.validAnswers.forEach(function(proposedanswer) {
        answersobj['proficiencyanswerId_' + proposedanswer.validAnswerId] = proposedanswer.proficiency;
        answersobj['frequencyanswerId_' + proposedanswer.validAnswerId] = proposedanswer.frequency;
      });
      setForm({
        questionId: question.questionId,
        answer: answersobj
      });
    } else if (isCheckQuestion(question.questionTypeDescription)) {
      let ckeckAnswers = {};
      question.clinicianAnswersList.forEach(function(proposedanswer) {
        ckeckAnswers['answerId' + proposedanswer.clinicianAnswerId] = proposedanswer.clinicianAnswerId;
      });
      setForm({
        questionId: question.questionId,
        answer: ckeckAnswers
      });
      const selectedOption = question.validAnswers.find(option => option.validAnswerId === parseInt(ckeckAnswers, 10));
      if (selectedOption) {
        setSelectedExtendedDescription(selectedOption.questionExtendedDescription || '');
        setSelectedOtherDetail(splitSentences(selectedOption.validOtherDetail || '') || '');
      } else {
        const firstOption = question.validAnswers[0];
        setSelectedExtendedDescription(firstOption.questionExtendedDescription || '');
        setSelectedOtherDetail('');
      }
    } else if (isDropMultipleQuestion(question.questionTypeDescription)) {
      if (Array.isArray(question.clinicianAnswers) && question.clinicianAnswers.length > 0) {
        setForm({
          questionId: question.questionId,
          answer: question.clinicianAnswers
        });
      } else {
        setForm({
          questionId: question.questionId,
          answer: []
        });
      }
      const selectedOptions = question.validAnswers.filter(validAnswer =>
        question.clinicianAnswers.includes(validAnswer.validAnswerId)
      );
      const selectedOtherDetails = selectedOptions.map(value => {
        return value.validOtherDetail || '';
      });
      setSelectedExtendedDescription(question.validAnswers[0].questionExtendedDescription || '');
      if (Array.isArray(selectedOtherDetails) && selectedOtherDetails.length > 0) {
        setSelectedOtherDetail(selectedOtherDetails.join('<br><br>'));
      } else {
        setSelectedOtherDetail('');
      }
    }
    if (question.inComments) {
      setInComments(question.inComments);
    } else {
      setInComments('');
    }
  };

  const toastErrorMessage = error => {
    const resMessage =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    if (resMessage === 'Index: 0, Size: 0') {
      toast.error('An error has occurred! Please contact customer service!');
      close();
    } else {
      toast.error(`${resMessage}`);
    }
  };

  const isDropQuestion = questionTypeDescription => {
    return questionTypeDescription === 'drop_1_1';
  };

  const isDropMultipleQuestion = questionTypeDescription => {
    return questionTypeDescription === '1_9_*';
  };

  const isRadioQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      if (questionTypeDescription === 'true_false') {
        return true;
      }
      const patternValue = qPattern(questionTypeDescription);
      if (Number.isNaN(patternValue)) {
        return false;
      }
      if (patternValue === 1 && !questionTypeDescription.toLowerCase().includes('drop')) {
        return true;
      }
    }
    return false;
  };

  const isCheckListQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      return questionTypeDescription.toLowerCase().includes('checklist');
    }
    return false;
  };

  const isCheckQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      let patternValue = qPattern(questionTypeDescription);
      if (Number.isNaN(patternValue)) return false;
      if (patternValue > 1) return true;
    }
    return false;
  };

  const questionType = questionTypeDescription => {
    if (isRadioQuestion(questionTypeDescription)) {
      return RADIOQUESTION_TYPE;
    }
    if (isCheckListQuestion(questionTypeDescription)) {
      return CHECKLISTQUESTION_TYPE;
    }
    if (isCheckQuestion(questionTypeDescription)) {
      return CHECKQUESTION_TYPE;
    }
    if (isDropQuestion(questionTypeDescription)) {
      return DROPQUESTION_TYPE;
    }
    if (isDropMultipleQuestion(questionTypeDescription)) {
      return DROP_MULTIPLE_QUESTION_TYPE;
    }
    return UNKNOWN_QUESTION_TYPE;
  };

  const asyncUpdateQuestion = qType => {
    let updateResult;
    const comments = isGuidedExam() ? inComments : null;
    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        updateResult = ExamService.updateQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer),
          exam.clinicianAssessmentId,
          comments
        );
        break;
      case CHECKLISTQUESTION_TYPE:
        updateResult = ExamService.updateQuestionChecklist(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer)
        );
        break;
      default:
        break;
    }
    return updateResult;
  };

  // Modificación en onLastClick para que solo se muestre el diálogo de confirmación si la pregunta es válida
  const onLastClick = e => {
    e.preventDefault();
    // Primero validamos que la respuesta es válida
    const isValid = isValidQuestion();
    if (!isValid) {
      setInvalidQuestion(true);
      return;
    }
    // Si es la última pregunta y es válida, se muestra el diálogo de confirmación
    if (currentQuestion.questionId === limits.lastQuestionId) {
      setShowConfirmDialog(true);
      return;
    }
    let updateQuestionService;
    const qType = questionType(currentQuestion.questionTypeDescription);
    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        updateQuestionService = asyncUpdateQuestion(qType);
        break;
      case CHECKLISTQUESTION_TYPE:
        if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
          setInvalidQuestion(isValid);
        } else {
          setInvalidQuestion(false);
          updateQuestionService = asyncUpdateQuestion(qType);
        }
        break;
      default:
        console.log(`Sorry, unknown question. ${qType}`);
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          let lastindexidis = currentQuestion.questionId >= limits.lastQuestionId ? limits.lastQuestionId : 0;
          ExamService.nextQuestion(exam.examId, user.id, lastindexidis, exam.clinicianAssessmentId)
            .then(response => {
              setInvalidQuestion(false);
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    }
  };

  const onFirstClick = e => {
    e.preventDefault();
    if (currentQuestion.questionId === limits.firstQuestionId) return;
    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);
    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(exam.examId, user.id, limits.firstQuestionId, exam.clinicianAssessmentId);
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          setInvalidQuestion(false);
          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(exam.examId, user.id, limits.firstQuestionId, exam.clinicianAssessmentId);
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(exam.examId, user.id, limits.firstQuestionId, exam.clinicianAssessmentId);
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);
              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);
              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion) {
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);
            fillCurrentAnswer(response);
            setCurrentQuestion(response);
            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      }
    }
  };

  const onPreviousClick = e => {
    e.preventDefault();
    if (currentQuestion.questionId === limits.firstQuestionId) return;
    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);
    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(
        exam.examId,
        user.id,
        currentQuestion.questionId - 1,
        exam.clinicianAssessmentId
      );
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          setInvalidQuestion(false);
          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId - 1,
          exam.clinicianAssessmentId
        );
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId - 1,
          exam.clinicianAssessmentId
        );
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);
              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);
              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion) {
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);
            fillCurrentAnswer(response);
            setCurrentQuestion(response);
            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      }
    }
  };

  const paginatorItemClick = e => {
    e.preventDefault();
    if (currentQuestion.questionId === parseInt(e.target.dataset['questionnumber'])) return;
    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);
    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(
        exam.examId,
        user.id,
        parseInt(e.target.dataset['questionnumber']),
        exam.clinicianAssessmentId
      );
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          setInvalidQuestion(false);
          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          parseInt(e.target.dataset['questionnumber']),
          exam.clinicianAssessmentId
        );
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          parseInt(e.target.dataset['questionnumber']),
          exam.clinicianAssessmentId
        );
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);
              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);
              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion)
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);
            fillCurrentAnswer(response);
            setCurrentQuestion(response);
            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
    }
  };

  const enabledNextButton = button => {
    if (!button) return;
    button.disabled = false;
  };

  const disabledNextButton = button => {
    if (!button) return;
    button.disabled = true;
  };

  const nextQuestionAction = response => {
    setInvalidQuestion(false);
    fillCurrentAnswer(response);
    setCurrentQuestion(response);
    if (response.questionId > lastquestionid) setLastquestionid(response.questionId);
    setIsLoaded(true);
    setInvalidMessage('');
  };

  const onNextClick = e => {
    e.preventDefault();
    const nextButton = isGuidedExam() ? document.getElementById('nextButton') : null;
    disabledNextButton(nextButton);
    if (currentQuestion.questionId === limits.lastQuestionId) return;
    let updateQuestionService;
    let isValid = isValidQuestion();
    const qType = questionType(currentQuestion.questionTypeDescription);
    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        if (isValid) {
          updateQuestionService = asyncUpdateQuestion(qType);
        } else {
          setInvalidQuestion(true);
          enabledNextButton(nextButton);
        }
        break;
      case CHECKLISTQUESTION_TYPE:
        if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
          setInvalidQuestion(isValid);
        } else if (!isValid) {
          setInvalidQuestion(true);
          enabledNextButton(nextButton);
        } else {
          setInvalidQuestion(false);
          updateQuestionService = asyncUpdateQuestion(qType);
        }
        break;
      default:
        console.log(`Sorry, unknown question. ${qType}`);
        enabledNextButton(nextButton);
    }
    if (isValid)
      if (updateQuestionService) {
        updateQuestionService
          .then(response => {
            let nextQuestionService;
            if (isGuidedExam()) {
              nextQuestionService = ExamService.nextQuestion(exam.examId, user.id, 0, exam.clinicianAssessmentId);
            } else {
              nextQuestionService = ExamService.nextQuestion(
                exam.examId,
                user.id,
                currentQuestion.questionId + 1,
                exam.clinicianAssessmentId
              );
            }
            nextQuestionService
              .then(response => {
                if (isGuidedExam()) {
                  nextQuestionAction(response);
                  setTimeout(() => {
                    enabledNextButton(nextButton);
                  }, NEXT_ACTION_WAIT);
                } else {
                  nextQuestionAction(response);
                  enabledNextButton(nextButton);
                }
              })
              .catch(error => {
                toastErrorMessage(error);
                enabledNextButton(nextButton);
              });
          })
          .catch(error => {
            toastErrorMessage(error);
            enabledNextButton(nextButton);
          });
      }
  };

  const isValidQuestion = () => {
    if (!currentQuestion) return false; // Evita acceder a propiedades de null
    let result = false;
    setInvalidMessage('');
    if (form.answer) {
      let qType = currentQuestion.questionTypeDescription;
      // Resto de la validación...
      if (isRadioQuestion(qType) || isDropQuestion(qType)) {
        result = form.answer !== '';
        if (result && isGuidedExam()) {
          let selectedOther = currentQuestion.validAnswers.some(validAnswer =>
            validAnswer['validAnswerId'] === parseInt(form.answer) &&
            validAnswer['validAnswerText'].toString().toLowerCase().trim() === OTHER_STR
          );
          if (selectedOther && !inComments) {
            setInvalidMessage('If Other is selected you must include Additional Notes.');
            result = false;
          }
        }
      } else if (isCheckListQuestion(qType)) {
        let properties = Object.getOwnPropertyNames(form.answer);
        result =
          currentQuestion.validAnswers.every(proposedanswer => {
            let findkey = 'proficiencyanswerId_' + proposedanswer.validAnswerId;
            let found = properties.find(property => property === findkey);
            let descriptor = Object.getOwnPropertyDescriptor(form.answer, findkey);
            return found && descriptor.value !== null && descriptor.value !== 0;
          }) &&
          currentQuestion.validAnswers.every(proposedanswer => {
            let findkey = 'frequencyanswerId_' + proposedanswer.validAnswerId;
            let found = properties.find(property => property === findkey);
            let descriptor = Object.getOwnPropertyDescriptor(form.answer, findkey);
            return found && descriptor.value !== null && descriptor.value !== 0;
          });
      } else if (isCheckQuestion(qType)) {
        let entries = Object.entries(form.answer);
        let answerQuantity = entries.reduce((count, entry) => (entry[1] ? count + 1 : count), 0);
        result = answerQuantity > 0;
      } else if (isDropMultipleQuestion(qType)) {
        result = form.answer && Array.isArray(form.answer.answer) && form.answer.answer.length > 0;
      }
    }
    return result;
  };
  

  const getAnswer = answer => {
    if (isRadioQuestion(currentQuestion.questionTypeDescription)) {
      return answer.answerId ? [parseInt(answer.answerId)] : [parseInt(answer)];
    } else if (isCheckListQuestion(currentQuestion.questionTypeDescription)) {
      let checklistAnswers = [];
      let checklistProperties = Object.entries(answer);
      currentQuestion.validAnswers.forEach(proposedanswer => {
        let findproficiencykey = 'proficiencyanswerId_' + proposedanswer.validAnswerId;
        let findfrequencykey = 'frequencyanswerId_' + proposedanswer.validAnswerId;
        let foundproficiency = checklistProperties.find(property => property[0] === findproficiencykey);
        let foundfrequency = checklistProperties.find(property => property[0] === findfrequencykey);
        checklistAnswers.push({
          answerId: proposedanswer.validAnswerId,
          proficiency: foundproficiency ? foundproficiency[1] : 0,
          frequency: foundfrequency ? foundfrequency[1] : 0
        });
      });
      return checklistAnswers;
    } else if (isCheckQuestion(currentQuestion.questionTypeDescription)) {
      let multiAnswers = [];
      let multiProperties = Object.entries(answer);
      multiAnswers = multiProperties
        .filter(answer => answer[1])
        .map(answer => parseInt(answer[1]));
      return multiAnswers;
    } else if (isDropQuestion(currentQuestion.questionTypeDescription)) {
      return [parseInt(answer)];
    } else if (isDropMultipleQuestion(currentQuestion.questionTypeDescription)) {
      let multiAnswers = [];
      if (Array.isArray(answer.answer)) {
        const answerArr = answer.answer;
        multiAnswers = answerArr.map(ans => parseInt(ans));
      } else if (typeof answer === 'object' && answer !== null) {
        let multiProperties = Object.entries(answer);
        multiAnswers = multiProperties
          .filter(property => property[1] !== null && property[1] !== undefined)
          .map(property => parseInt(property[1]));
      }
      return multiAnswers;
    } else return [];
  };

  const onPressEnterKey = e => {
    if (e.key === 'Enter') {
      onNextClick(e);
    }
  };

  const handle_OnSaveExit = e => {
    setShowSaveExitDialog(true);
    return;
  }

  const handle_OnSubmit = e => {
    e.preventDefault();
    let service;
    let isValid = isValidQuestion();
    const qType = questionType(currentQuestion.questionTypeDescription);
    if (qType === CHECKLISTQUESTION_TYPE) {
      if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
        setInvalidQuestion(isValid);
      } else if (!isValid) {
        setInvalidQuestion(true);
      } else {
        setInvalidQuestion(false);
        service = ExamService.updateQuestionChecklist(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer)
        );
      }
    } else {
      if (!isValid) {
        setInvalidQuestion(true);
      } else {
        setInvalidQuestion(false);
        service = ExamService.updateQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer),
          exam.clinicianAssessmentId,
          inComments
        );
      }
    }
    if (service) {
      const submitSpinner = document.getElementById('submitSpinner');
      submitSpinner.style.display = 'inline-block';
      const submitButton = document.getElementById('submitButton');
      submitButton.disabled = true;
      service
        .then(response => {
          ExamService.complete(exam.examId, user.id, exam.clinicianAssessmentId)
            .then(response => {
              const newExamStatus = { ...examStatus, endstatus: 'completed' };
              setExamStatus(newExamStatus);
              toast.success(`Exam completed successfully.`);
              close();
              setVisible(false);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    }
  };

  const handle_OnChange = data => {
    setForm({
      ...form,
      answer: data.selectedValue
    });
    setSelectedOtherDetail(data.selectedOtherDetail || '');
  };

  const handle_OnChangeChecked = e => {
    let answers = {};
    if (e.target.type === 'checkbox') {
      answers = {
        ...form.answer,
        [e.target.name]: e.target.checked ? e.target.value : ''
      };
    } else if (e.target.type === 'radio') {
      answers = {
        ...form.answer,
        [e.target.name]: e.target.value
      };
    }
    setForm({
      ...form,
      answer: answers
    });
  };

  const handle_OnChangeSelected = data => {
    let answers = {};
    if (data.selectedValues.length > 0) {
      answers = {
        ...form.answer,
        answer: data.selectedValues
      };
      setForm({
        ...form,
        answer: answers
      });
    } else {
      answers = { answerIdnull: null };
      setForm({
        answer: answers
      });
    }
    setSelectedOtherDetail(data.selectedOtherDetail || '');
  };

  const close = () => {
    setIsLoaded(false);
    setCurrentQuestion(null);
    setLastquestionid(0);
    setForm(initialForm);
    setInComments('');
    if (
      examStatus.endstatus.length > 0 &&
      examStatus.startstatus.toLowerCase() !== examStatus.endstatus.toLowerCase()
    ) {
      setExamStateChanged(true);
      setExamStatus({ startstatus: '', endstatus: '' });
    }
    setVisible(false);
  };

  const onClose = () => {
    let doClose = true;
    if (currentQuestion) {
      let qType = questionType(currentQuestion.questionTypeDescription);
      if (currentQuestion.questionId !== limits.lastQuestionId && qType === CHECKLISTQUESTION_TYPE) {
        let isValid = isValidQuestion();
        if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
          doClose = false;
          let updateQuestionService = asyncUpdateQuestion(qType);
          updateQuestionService
            .then(response => {
              doClose = true;
              close();
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        }
      }
    }
    if (doClose) {
      close();
    }
  };

  const qPattern = strValue => {
    if (strValue) {
      let splited = strValue.split('_');
      return parseInt(splited[2]);
    }
    return 0;
  };

  const PaginatorRender = () => {
    let questions = [];
    const maxPages = IS_MOBILE_VIEW ? 0 : 5;
    for (let question = limits.firstQuestionId; question < lastquestionid; question++) {
      questions.push(question);
    }
    const currentPageIndex = currentQuestion.questionId - limits.firstQuestionId;
    let start = 0;
    let end = 0;
    if (questions.length > maxPages) {
      if (currentPageIndex < maxPages / 2) {
        start = 0;
        end = maxPages;
      } else if (currentPageIndex >= questions.length - maxPages / 2) {
        start = questions.length - maxPages;
        end = questions.length;
      } else {
        start = currentPageIndex - Math.floor(maxPages / 2);
        end = currentPageIndex + Math.ceil(maxPages / 2);
      }
    } else {
      start = 0;
      end = questions.length;
    }
    const visibleQuestions = questions.slice(start, end);
    return visibleQuestions.map((question, index) => {
      return (
        <PaginationItem key={question}>
          <Button className="page-link" type="button" data-questionnumber={question} onClick={paginatorItemClick}>
            {question - limits.firstQuestionId + 1}
          </Button>
        </PaginationItem>
      );
    });
  };

  const backdropManager = () => {
    if (
      currentQuestion &&
      currentQuestion.questionTypeDescription &&
      isCheckListQuestion(currentQuestion.questionTypeDescription) &&
      backdrop === 'static'
    ) {
      return 'static';
    } else {
      return backdrop;
    }
  };

  const isFormExam = useCallback(() => {
    return exam && exam.examType && exam.examType.toLowerCase() === 'form';
  }, [exam]);

  const isGuidedExam = useCallback(() => {
    return exam && exam.examType && exam.examType.toLowerCase() === 'guided';
  }, [exam]);

  const findQuestionIndexById = (list, id) => {
    return list.findIndex(question => question.questionId === id);
  };

  const findQuestionByCaValidAnswersId = (list, id) => {
    return list.find(q => q.questionCaValidAnswersId === id);
  };

  const InitializeAnsweredQuestions = () => {
    if (exam) {
      ExamService.getCurrentSelections(exam.clinicianAssessmentId)
        .then(response => {
          if (response.data) {
            let questionlist = response.data.map((question, index) => {
              return {
                index: index + 1,
                questionId: question.questionId,
                questionText: question.questionText,
                questionAnswer: question.validAnswerText,
                questionStandard: question.standard,
                questionCaValidAnswersId: question.caValidAnswersId
              };
            });
            const q = findQuestionByCaValidAnswersId(questionlist, '66666');
            const index = findQuestionIndexById(questionlist, q.questionId - 1) + 1;
            const page = Math.floor(index / ITEMSBYPAGE) + 1;
            setCurrentNavigationPage(page);
            setCurrentAnsweredQuestions(questionlist);
          }
        })
        .catch(error => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    function close() {
      setVisible(false);
      setIsLoaded(false);
      setCurrentQuestion(null);
      setLastquestionid(0);
      setForm(initialForm);
      setExamStatus({ startstatus: '', endstatus: '' });
    }
    function examStart() {
      ExamService.start(exam.examId, user.id, exam.clinicianAssessmentId)
        .then(response => {
          setExamStatus({ startstatus: exam.status.toLowerCase(), endstatus: 'in progress' });
          ExamService.nextQuestion(exam.examId, user.id, 0, exam.clinicianAssessmentId)
            .then(response => {
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              setLastquestionid(response.questionId);
              setActiveTab('0');
              setCurrentNavigationPage(1);
              setSelectedNavigationRow(null);
              setNextBotonIsVisible(true);
              setIsLoaded(true);
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message === 'Index: 0, Size: 0') {
                  toast.error('An error has occurred! Please contact customer service!');
                } else {
                  toast.error(error.response.data.message);
                }
              } else {
                toast.error(error.message);
              }
              close();
            });
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error(`${resMessage}`);
        });
    }
    if (visible && exam && examStatus.endstatus !== 'completed') {
      examStart();
    }
    // eslint-disable-next-line
  }, [visible, setVisible, exam, user]);

  useEffect(() => {
    function examLimits() {
      ExamService.limits(exam.examId)
        .then(response => {
          setLimits(response.data);
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error(`${resMessage}`);
        });
    }
    if (exam) {
      examLimits();
      if (isFormExam() || isGuidedExam()) {
        setIsDeselectable(true);
      }
    }
    setInvalidQuestion(false);
  }, [exam, isFormExam, isGuidedExam]);

  useEffect(() => {
    if (form.answer && isValidQuestion()) {
      setInvalidQuestion(false);
    }
  }, [form.answer]);
  
  useEffect(() => {
    setTimeout(() => {
      const containers = document.querySelectorAll('.custom-modal-content, .checklist-modal-content');
      containers.forEach(container => {
        container.scrollTop = 0;
      });
    }, 50);
  }, [currentQuestion]);

  const mytoggle = () => setVisible(!visible);

  const shouldShowElement = () => {
    return isLoaded && !isFormExam() && !isGuidedExam();
  };

  const TapItemCompany = props => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    return (
      <>
        <NavItem className="cursor-pointer" id="tapitemcompany" key={props.index}>
          <NavLink
            style={{ padding: '.2rem 1rem' }}
            className={classNames({ active: activeTab === '3' })}
            onClick={() => {
              window.open(`https://${currentQuestion.questionInfoUrl}`, '_blank');
            }}
          >
            <img
              className="d-block mx-auto"
              src={require(`../../../assets/img/questions/image_company.jpg`)}
              alt="shield"
              width={25}
            />
          </NavLink>
        </NavItem>
        <Tooltip
          placement="left"
          isOpen={tooltipOpen}
          target="tapitemcompany"
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          More info about current question
        </Tooltip>
      </>
    );
  };

  const TapItemNavigationContent = () => {
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row) {
        if (row.questionText === selectedNavigationRow) {
          style.backgroundColor = '#D0E8FF';
        }
        if (row.questionText.toLowerCase() === currentQuestion.questionText.toLowerCase()) {
          style.backgroundColor = '#f2dede';
          style.color = '#a94442';
        }
      }
      return style;
    };
    const tableGuidedNavigationRowEvents = {
      onDoubleClick: (e, row, rowIndex) => {
        setSelectedNavigationRow(row.questionText);
        setShowResetAnswersDialog({ showdialog: true, questionidselected: row.questionId });
      }
    };
    const tableGuidedNavigationOnPageChange = page => {
      setCurrentNavigationPage(page);
    };
    return (
      <PageFilterableTable
        data={currentAnsweredQuestions.filter(item => item.questionCaValidAnswersId !== '77777')}
        columns={columns}
        keyField="index"
        enableNewButton={false}
        sizePerPage={ITEMSBYPAGE}
        rowEvents={tableGuidedNavigationRowEvents}
        rowStyle={rowStyle}
        page={currentNavigationPage}
        onPageChange={tableGuidedNavigationOnPageChange}
      />
    );
  };

  const tabQuestion_onClick = () => {
    setNextBotonIsVisible(true);
    setSelectedNavigationRow(null);
    setCurrentNavigationPage(1);
  };

  const tabNavigation_onClick = () => {
    InitializeAnsweredQuestions();
    setNextBotonIsVisible(false);
  };

  const radioQuestionTabContent = {
    tab1: {
      title: 'Question',
      onclick: () => tabQuestion_onClick(),
      content: isLoaded && isRadioQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
        <>
          <RadioQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            useranswer={
              form.answer ||
              (currentQuestion.clinicianAnswersList && currentQuestion.clinicianAnswersList.length > 0)
                ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId
                : 0
            }
            image={currentQuestion.imageLocation}
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
            isDeselectable={isDeselectable}
            invalidMessage={invalidMessage}
          />
          <FormGroup className="mt-3">
            <textarea
              id="inComments"
              name="inComments"
              value={inComments}
              onChange={handleNotesChange}
              placeholder="Additional Notes"
              className="form-control"
              style={{ width: '100%', minHeight: '37px', height: '37px' }}
            />
          </FormGroup>
        </>
      )
    },
    tab2: {
      title: 'Navigation',
      tooltip: (
        <CustomToolTip>
          Use the Navigation tab to see your answers and defaults.
          <br /> Edit by double-clicking your answers.
        </CustomToolTip>
      ),
      onclick: () => tabNavigation_onClick(),
      content: (
        <>
          <TapItemNavigationContent />
          <p>{currentAnsweredQuestions.find(item => item.questionCaValidAnswersId === '77777')?.questionText}</p>
        </>
      )
    },
    tab3: {
      tapitem: <TapItemCompany />,
      content: null
    }
  };

  const dropQuestionTabContent = {
    tab1: {
      title: 'Question',
      onclick: () => tabQuestion_onClick(),
      content: isLoaded && isDropQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
        <>
          <DropQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={form.answer}
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
            invalidMessage={invalidMessage}
          />
          <FormGroup className="mt-3">
            <textarea
              id="inComments"
              name="inComments"
              value={inComments}
              onChange={handleNotesChange}
              placeholder="Additional Notes"
              className="form-control"
              style={{ width: '100%', minHeight: '37px', height: '37px' }}
            />
          </FormGroup>
        </>
      )
    },
    tab2: {
      title: 'Navigation',
      tooltip: (
        <CustomToolTip>
          Use the Navigation tab to see your answers and defaults.
          <br /> Edit by double-clicking your answers.
        </CustomToolTip>
      ),
      onclick: () => tabNavigation_onClick(),
      content: (
        <>
          <TapItemNavigationContent />
          <p>{currentAnsweredQuestions.find(item => item.questionCaValidAnswersId === '77777')?.questionText}</p>
        </>
      )
    },
    tab3: {
      tapitem: <TapItemCompany />,
      content: null
    }
  };

  const dropMultiQuestionTabContent = {
    tab1: {
      title: 'Question',
      onclick: () => tabQuestion_onClick(),
      content: isLoaded && isDropMultipleQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
        <>
          <DropMultipleQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={currentQuestion.clinicianAnswers}
            onChange={handle_OnChangeSelected}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
          />
          <FormGroup className="mt-3">
            <textarea
              id="inComments"
              name="inComments"
              value={inComments}
              onChange={handleNotesChange}
              placeholder="Additional Notes"
              className="form-control"
              style={{ width: '100%', minHeight: '37px', height: '37px' }}
            />
          </FormGroup>
        </>
      )
    },
    tab2: {
      title: 'Navigation',
      tooltip: (
        <CustomToolTip>
          Use the Navigation tab to see your answers and defaults.
          <br /> Edit by double-clicking your answers.
        </CustomToolTip>
      ),
      onclick: () => tabNavigation_onClick(),
      content: (
        <>
          <TapItemNavigationContent />
          <p>{currentAnsweredQuestions.find(item => item.questionCaValidAnswersId === '77777')?.questionText}</p>
        </>
      )
    },
    tab3: {
      tapitem: <TapItemCompany />,
      content: null
    }
  };

  return (
    <Modal
      isOpen={visible}
      toggle={mytoggle}
      style={{ margin: '0' }}
      contentClassName={
        currentQuestion && isCheckListQuestion(currentQuestion.questionTypeDescription)
          ? 'checklist-modal-content'
          : 'custom-modal-content'
      }
      onKeyDown={onPressEnterKey}
      backdrop={backdropManager()}
      onClosed={onClose}
    >
      <Modal isOpen={showConfirmDialog} toggle={() => setShowConfirmDialog(!showConfirmDialog)}>
        <ModalHeader>{isTruck ? 'Chassis Report' : 'Exam'}</ModalHeader>
        <ModalBody>
          {isTruck
            ? `Choose Confirm to complete the interview.
            The completed Chassis Report will be ready for Viewing, Downloading or Editing by clicking on the proper menu option in the Report column for ${exam.clinicianAssessmentId} Report ID.
            Choose Cancel to use Navigation tab to make changes.`
            .split("\n")
            .map((line, index) => <p key={index}>{line}</p>)
            : 'Are you sure you want to complete this exam?'}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowConfirmDialog(!showConfirmDialog)}>Cancel</Button>
          <Button
            color="danger"
            onClick={e => {
              handle_OnSubmit(e);
              setShowConfirmDialog(false);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showSaveExitDialog} toggle={() => setShowSaveExitDialog(!showSaveExitDialog)}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          {isTruck
            ? 'This Chassis Report is incomplete. You will need to return and complete prior to viewing the Chassis Report.'
            : 'Are you sure you want to save and exit this exam?'}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowSaveExitDialog(!showSaveExitDialog)}>Cancel</Button>
          <Button
            color="danger"
            onClick={e => {
              setShowSaveExitDialog(false);
              mytoggle();
            }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showResetAnswersDialog.showdialog}
        toggle={() =>
          setShowResetAnswersDialog({
            showdialog: !showResetAnswersDialog.showdialog,
            questionidselected: showResetAnswersDialog.questionidselected
          })
        }
      >
        <ModalHeader>Chassis Report Warning</ModalHeader>
        <ModalBody>This action will reset all your answers after selected question. Do you want to proceed?</ModalBody>
        <ModalFooter>
          <Button
            onClick={() =>
              setShowResetAnswersDialog({
                showdialog: !showResetAnswersDialog.showdialog,
                questionidselected: showResetAnswersDialog.questionidselected
              })
            }
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={e => {
              setShowResetAnswersDialog({
                showdialog: !showResetAnswersDialog.showdialog,
                questionidselected: showResetAnswersDialog.questionidselected
              });
              handleDoubleClickOnNavigationGuided(showResetAnswersDialog.questionidselected);
              setSelectedNavigationRow(null);
              setCurrentNavigationPage(1);
              setNextBotonIsVisible(true);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      {exam && (
        <ModalHeader
          toggle={mytoggle}
          close={
            <button className="close float-none" type="button" aria-label="Close" onClick={handle_OnSaveExit}>
              <span aria-hidden="true">
                <span className="fs-1 font-weight-light align-middle" style={{ fontSize: '0.7em' }}>
                  Save and Exit
                </span>{' '}
                x
              </span>
            </button>
          }
        >
          {exam.examName}
        </ModalHeader>
      )}
      <ModalBody>
        {!isLoaded && <Loader />}
        {isLoaded && isRadioQuestion(currentQuestion.questionTypeDescription) && !isGuidedExam() && (
          <RadioQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            useranswer={
              form.answer ||
              (currentQuestion.clinicianAnswersList && currentQuestion.clinicianAnswersList.length > 0)
                ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId
                : 0
            }
            image={currentQuestion.imageLocation}
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
            isDeselectable={isDeselectable}
          />
        )}
        {isLoaded && isRadioQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
          <CustomTab activeTab={activeTab} setActiveTab={setActiveTab} tabContents={radioQuestionTabContent} />
        )}
        {isLoaded && isCheckListQuestion(currentQuestion.questionTypeDescription) && (
          <>
            <CheckListDescription />
            <CheckListQuestion
              question={currentQuestion.questionText}
              proposedanswers={currentQuestion.validAnswers}
              answer={currentQuestion.clinicianAnswer}
              onChange={handle_OnChangeChecked}
              invalid={invalidQuestion}
              inline
            />
          </>
        )}
        {isLoaded && isCheckQuestion(currentQuestion.questionTypeDescription) && (
          <CheckQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={currentQuestion.clinicianAnswersList.map(answer => answer.clinicianAnswerId)}
            onChange={handle_OnChangeChecked}
            invalid={invalidQuestion}
            invalidMessage={invalidMessage}
          />
        )}
        {isLoaded && isDropQuestion(currentQuestion.questionTypeDescription) && !isGuidedExam() && (
          <DropQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={form.answer}
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
          />
        )}
        {isLoaded && isDropQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
          <CustomTab activeTab={activeTab} setActiveTab={setActiveTab} tabContents={dropQuestionTabContent} />
        )}
        {isLoaded && isDropMultipleQuestion(currentQuestion.questionTypeDescription) && !isGuidedExam() && (
          <DropMultipleQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={currentQuestion.clinicianAnswersList ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId : 0}
            onChange={handle_OnChangeSelected}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
          />
        )}
        {isLoaded && isDropMultipleQuestion(currentQuestion.questionTypeDescription) && isGuidedExam() && (
          <CustomTab activeTab={activeTab} setActiveTab={setActiveTab} tabContents={dropMultiQuestionTabContent} />
        )}
      </ModalBody>
      <ModalFooter className="d-flex">
        {shouldShowElement() && (
          <div className="mr-auto ">
            <PageNofM
              className="my-auto"
              n={questionIndex}
              m={limits && limits.lastQuestionId ? limits.lastQuestionId - limits.firstQuestionId + 1 : 0}
            />
          </div>
        )}
        <div>
          <Pagination aria-label="Page navigation example" size="md">
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId > limits.firstQuestionId && (
                <Button
                  className="page-link"
                  type="button"
                  data-questionnumber={limits.firstQuestionId}
                  onClick={onFirstClick}
                >
                  {IS_MOBILE_VIEW ? '|<<' : 'First'}
                </Button>
              )}
            </PaginationItem>
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId > limits.firstQuestionId && (
                <Button className="page-link" type="button" onClick={onPreviousClick}>
                  {IS_MOBILE_VIEW ? 'Prev' : 'Prev'}
                </Button>
              )}
            </PaginationItem>
            {shouldShowElement() && <PaginatorRender />}
            <PaginationItem>
              {isLoaded && currentQuestion.questionId !== limits.lastQuestionId && (
                <div className="fixed-next-btn">
                <Button
                  id="nextButton"
                  className="page-link"
                  type="button"
                  onClick={onNextClick}
                  style={{ display: nextBotonIsVisible ? 'block' : 'none' }}
                >
                  {IS_MOBILE_VIEW ? 'Next' : 'Next'}
                </Button>
                <div><p><br></br><br></br></p></div>
              </div>
              )}
            </PaginationItem>
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId !== limits.lastQuestionId && (
                 <div className="fixed-next-btn">
                  <Button className="page-link" type="button" onClick={onLastClick}>
                    {IS_MOBILE_VIEW ? 'Last' : 'Last'}
                  </Button>
                 </div>
              )}
            </PaginationItem>
            <PaginationItem>
              {isLoaded && currentQuestion.questionId === limits.lastQuestionId && (
                <div className="fixed-next-btn">
                  <Button id="submitButton" className="page-link" type="button" onClick={onLastClick}>
                    <Spinner
                      id="submitSpinner"
                      size="sm"
                      color="success"
                      style={{ display: 'none' }}
                      aria-hidden="true"
                      role="status"
                      className="mr-1"
                    />
                    <span>{'Complete'}</span>
                  </Button>
                  <div><p><br></br><br></br></p></div>
                </div>
              )}
            </PaginationItem>
          </Pagination>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ExamDialog;
