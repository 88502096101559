import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import MultiSelect from '../../../hcare/components/MultiSelect';
import { removeChars } from '../../../helpers/hcutils';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropMultipleQuestion = ({
  question,
  proposedanswers,
  onChange,
  answer,
  selectedExtendedDescription,
  selectedOtherDetail,
  invalid,            // Prop para indicar error
  invalidMessage      // Mensaje de error
}) => {
  const [selectedValues, setSelectedValues] = useState(Array.isArray(answer) ? answer : []);
  const prevAnswerRef = useRef();

  const options = useMemo(
    () =>
      proposedanswers.map(proposedanswer => ({
        value: proposedanswer.validAnswerId,
        label: proposedanswer.validAnswerText
      })),
    [proposedanswers]
  );

  // Efecto para propagar los valores preseleccionados al padre, filtrando valores nulos.
  useEffect(() => {
    // Aseguramos que answer sea un array y filtramos posibles nulos
    const filteredAnswer = Array.isArray(answer) ? answer.filter(item => item != null) : [];
    const prevAnswerStr = JSON.stringify(prevAnswerRef.current);
    const currentAnswerStr = JSON.stringify(filteredAnswer);
    if (prevAnswerStr !== currentAnswerStr) {
      prevAnswerRef.current = filteredAnswer;
      if (filteredAnswer.length > 0) {
        setSelectedValues(filteredAnswer);
        const selectedExtendedDescriptions = filteredAnswer.map(value => {
          const selectedOption = proposedanswers.find(
            option => option.validAnswerId === parseInt(value, 10)
          );
          return selectedOption ? selectedOption.questionExtendedDescription || '' : '';
        });
        const selectedOtherDetails = filteredAnswer.map(value => {
          const selectedOption = proposedanswers.find(
            option => option.validAnswerId === parseInt(value, 10)
          );
          return selectedOption ? selectedOption.validOtherDetail || '' : '';
        });
        onChange({
          selectedValues: filteredAnswer,
          selectedExtendedDescription: selectedExtendedDescriptions.join(' | '),
          selectedOtherDetail: selectedOtherDetails.join('<br><br>')
        });
      } else if (proposedanswers.length > 0) {
        setSelectedValues([]);
      }
    }
  }, [proposedanswers, answer, onChange]);

  const handleChange = selectedOptions => {
    let newSelectedValues = [];
    if (Array.isArray(selectedOptions)) {
      const objetoNone = selectedOptions.find(obj => obj.label.toLowerCase() === 'none');
      newSelectedValues = objetoNone ? [objetoNone.value] : selectedOptions.map(option => option.value);
    }
    // Filtramos valores nulos
    newSelectedValues = newSelectedValues.filter(item => item != null);
    setSelectedValues(newSelectedValues);

    const selectedExtendedDescriptions = newSelectedValues.map(value => {
      const selectedOption = proposedanswers.find(
        option => option.validAnswerId === parseInt(value, 10)
      );
      return selectedOption ? selectedOption.questionExtendedDescription || '' : '';
    });

    const selectedOtherDetails = newSelectedValues.map(value => {
      const selectedOption = proposedanswers.find(
        option => option.validAnswerId === parseInt(value, 10)
      );
      return selectedOption ? selectedOption.validOtherDetail || '' : '';
    });

    onChange({
      selectedValues: newSelectedValues,
      selectedExtendedDescription: selectedExtendedDescriptions.join(' | '),
      selectedOtherDetail: selectedOtherDetails.join('<br><br>')
    });
  };

  const formattedQuestion = removeChars(question);

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div style={{ overflowY: 'visible', lineHeight: '1.5em', paddingRight: '10px' }}>
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label htmlFor="selectOption" style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem', display: 'block' }}>
        {formattedQuestion}
      </Label>
      <MultiSelect
        name="answer"
        id="selectOption"
        options={options}
        isMulti
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        value={options.filter(option => selectedValues.includes(option.value))}
        onChange={handleChange}
      />
      {invalid && (
        <FormFeedback style={{ display: 'block' }}>
          {invalidMessage || 'Please select at least one answer!'}
        </FormFeedback>
      )}
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div
            style={{
              overflowY: 'visible',
              lineHeight: '1.5em',
              paddingRight: '10px',
              marginTop: '1rem'
            }}
          >
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: selectedOtherDetail }} />
          </div>
        </>
      )}
    </FormGroup>
  );
};

DropMultipleQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  answer: [],
  selectedExtendedDescription: '',
  selectedOtherDetail: '',
  invalid: false,
  invalidMessage: ''
};

DropMultipleQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.arrayOf(
    PropTypes.shape({
      validAnswerId: PropTypes.number.isRequired,
      validAnswerText: PropTypes.string.isRequired,
      validOtherDetail: PropTypes.string,
      questionExtendedDescription: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  answer: PropTypes.array,
  selectedExtendedDescription: PropTypes.string,
  selectedOtherDetail: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMessage: PropTypes.string
};

export default DropMultipleQuestion;
