import React from 'react';
import PropTypes, { array, string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Avatar from '../common/Avatar';
import LogoText from './LogoText';

const OrganizationLogo = ({ organizationLogo, width }) => {
  return <Avatar src={organizationLogo} rounded="soft" size="5xl" className="align-middle" />;
};

OrganizationLogo.propTypes = {
  organizationLogo: PropTypes.oneOfType([array, string]),
  width: PropTypes.number.isRequired
};

const LogoRender = ({ currentuserdata, organizationLogo, isDark, width }) => {
  if (!currentuserdata || !currentuserdata.department) return <></>;

  switch (currentuserdata.department.organization.logoTextAtr.toLowerCase().trim()) {
    case 'escore it':
    case '':
      return <></>;
    case 'logo':
      // Si tiene logo guardado mostrar el logo sino
      if (organizationLogo && organizationLogo[0].base64)
        return <OrganizationLogo organizationLogo={organizationLogo[0].base64} width={width} />;
      return <></>;
    default:
      // Si gl_logo_text == 'logo someString'
      if (currentuserdata.department.organization.logoTextAtr.toLowerCase().includes('logo')) {
        if (organizationLogo && organizationLogo[0].base64) {
          // Si tiene logo guardado se muestra el logo + el texto
          return (
            <div style={{ flexDirection: 'column' }}>
              <OrganizationLogo organizationLogo={organizationLogo[0].base64} width={width} />
              <LogoText organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '')} />
            </div>
          );
        } else {
          return <></>;
        }
      }
  }

  return <></>;
};

LogoRender.propTypes = {
  currentuserdata: PropTypes.object,
  organizationLogo: PropTypes.oneOfType([array, string]),
  // isDark: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired
};

const LogoVertical = ({ at, width, className, currentuserdata, organizationLogo, isDark, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none organizationlogo-center',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-2 mb-0': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
            'flex-center font-weight-extra-bold fs-5': at === 'report'
          },
          className
        )}
      >
        {/* {!currentuserdata && <></>} */}
        {(currentuserdata || organizationLogo) && (
          <LogoRender
            currentuserdata={currentuserdata}
            organizationLogo={organizationLogo}
            isDark={!!isDark}
            width={180}
            at={at}
          />
        )}
      </div>
    </Link>
  );
};

LogoVertical.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth', 'report']),
  width: PropTypes.number,
  className: PropTypes.string,
  currentuser: PropTypes.object
};

LogoVertical.defaultProps = { at: 'auth', width: 58 };

export default LogoVertical;
