import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Media, Button } from 'reactstrap';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import { toast } from 'react-toastify';

import UserService from '../../services/user.service';
import { ucFirst } from '../../helpers/hcutils';
import AppContext from '../../context/Context';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const ProfileDropdown = ({ currentuser, setCurrentUser }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [currentuserdata, setCurrentUserData] = useState(null);
  const [isLoadedUserData, setIsLoadedUserData] = useState(false);
  const {
    setReportCompanyFilter,
    setReportPeriodType,
    setReportSelectedOrganizationId,
    setReportSelectedOrganizationName,
    setReportExamResultFilter
  } = useContext(AppContext);

  const location = useLocation();

  const getUser = id => {
    UserService.find(id)
      .then(response => {
        setCurrentUserData(response.data);
        setIsLoadedUserData(true);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        toast.error(`Try again! ${resMessage}`);
      });
  };

  const formatUserName = () => {
    let fullname = '';

    if (isLoadedUserData && currentuserdata) {
      if (
        currentuserdata.firstname &&
        currentuserdata.firstname.length > 0 &&
        currentuserdata.lastname &&
        currentuserdata.lastname.length > 0
      ) {
        fullname = currentuserdata.firstname + ' ' + currentuserdata.lastname;
      } else if (currentuserdata.firstname && currentuserdata.firstname.length > 0) {
        fullname = currentuserdata.firstname;
      } else {
        fullname =
          currentuserdata.lastname && currentuserdata.lastname.length > 0 ? currentuserdata.lastname : 'Health Care';
      }
    }
    return fullname;
  };

  useEffect(() => {
    if (location.pathname.includes('guru')) return;

    if (currentuser) getUser(currentuser.id);
  }, [currentuser, location.pathname]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to="/pages/profile">
          <Button className="remove-border-on-focus" color="transparent" border="none">
            <Media tag={Flex} align="center">
              {isLoadedUserData && <Avatar name={formatUserName()} />}
            </Media>
          </Button>
        </Link>
      </div>

      {isLoadedUserData && (
        <Dropdown
          nav
          inNavbar
          isOpen={dropdownOpen}
          toggle={toggle}
          onMouseOver={() => {
            let windowWidth = window.innerWidth;
            windowWidth > 992 && setDropdownOpen(true);
          }}
          onMouseLeave={() => {
            let windowWidth = window.innerWidth;
            windowWidth > 992 && setDropdownOpen(false);
          }}
        >

          <DropdownToggle nav className="pr-0">
            <Media tag={Flex} align="center">
              <Media body className="d-block">
                <h5 className="mb-0 fs--1">{ucFirst(currentuser.roles[0])}</h5>
                <h5 className="mb-0 fs--1">{currentuser.username}</h5>
              </Media>
            </Media>
          </DropdownToggle>

          {/* no muestra el username */}
          {/* <DropdownToggle nav className="pr-0">
            <Media tag={Flex} align="center">
              <Media body className="d-none d-md-block">
                <h5 className="mb-0 fs--1">{ucFirst(currentuser.roles[0])}</h5>
                <h5 className="mb-0 fs--1">{currentuser.username}</h5>
              </Media>
            </Media>
          </DropdownToggle> */}

          <DropdownMenu right className="dropdown-menu-card">
            <div className="bg-white rounded-soft py-2">
              <DropdownItem tag={Link} to="/pages/about">
                About
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/pages/settings">
                Settings
              </DropdownItem>
              <DropdownItem
                tag={Link}
                to={
                  currentuserdata?.department?.organization?.type?.toLowerCase() === 'truck'
                    ? '/guru/login'
                    : '/authentication/login'
                }
                onClick={() => {
                  if (currentuser) {
                    setCurrentUser(null);
                    setReportCompanyFilter('');
                    setReportPeriodType('day');
                    setReportSelectedOrganizationId(0);
                    setReportSelectedOrganizationName('');
                    setReportExamResultFilter('');
                  }
                }}
              >
                Logout
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

export default ProfileDropdown;
